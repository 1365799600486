function basicHorizonView(element, calendar, viewName) {
	var t = this;

	// exports
	t.renderBasic = renderBasic;
	t.setHeight = setHeight;
	t.setWidth = setWidth;
	t.renderDayOverlay = renderDayOverlay;
	t.defaultSelectionEnd = defaultSelectionEnd;
	t.renderSelection = renderSelection;
	t.clearSelection = clearSelection;
	t.reportDayClick = reportDayClick; // for selection (kinda hacky)
	t.dragStart = dragStart;
	t.dragStop = dragStop;
	t.getHoverListener = function () {
		return hoverListener;
	};
	t.colLeft = colLeft;
	t.colRight = colRight;
	t.colContentLeft = colContentLeft;
	t.colContentRight = colContentRight;
	t.getIsCellAllDay = function () {
		return true;
	};
	t.allDayRow = allDayRow;
	t.getRowCnt = function () {
		return rowCnt;
	};
	t.getColCnt = function () {
		return colCnt;
	};
	t.getColWidth = function () {
		return colWidth;
	};
	t.getClustering = function () {
		return clustering;
	};
	t.getDaySegmentContainer = function () {
		return daySegmentContainer;
	};

	t.getNubContainer = function () {
		return nubContainer;
	};
	t.getDistanceContainer = function () {
		return distanceContainer;
	};

	t.getResources = calendar.fetchAllResources;

	// imports
	View.call(t, element, calendar, viewName);
	OverlayManager.call(t);
	SelectionManager.call(t);
	BasicHorizonEventRenderer.call(t);
	var opt = t.opt;
	var trigger = t.trigger;
	var renderOverlay = t.renderOverlay;
	var clearOverlays = t.clearOverlays;
	var daySelectionMousedown = t.daySelectionMousedown;
	var cellToDate = t.cellToDate;
	var dateToCell = t.dateToCell;
	var resourceGridRangeToSegments = t.resourceGridRangeToSegments;
	var createExternalDragDayDates = t.createExternalDragDayDates;
	var formatDate = calendar.formatDate;
	var calculateWeekNumber = calendar.calculateWeekNumber;

	// locals

	var table;
	var head;
	var headCells;
	var body;
	var bodyRows;
	var bodyCells;
	var bodyFirstCells;
	var bodyPosition;
	var firstRowCellInners;
	var firstRowCellContentInners;
	var daySegmentContainer;

	var nubContainer;
	var distanceContainer;

	var viewWidth;
	var viewHeight;
	var colWidth;
	var weekNumberWidth;
	var rowCnt, colCnt, clustering;
	var showNumbers;
	var coordinateGrid;
	var hoverListener;
	var colPositions;
	var colContentPositions;

	var tm;
	var colFormat;
	var showWeekNumbers;

	//Customized for seedcode
	var breakoutHeader = opt('breakoutHeader');
	var breakoutHead;
	var breakoutHeadCells;
	var breakoutTitleRows;
	var breakoutTitleRowHeight;
	var headerDayClass;
	var isChrome = opt('isChrome');
	var isMobileDevice = opt('isMobileDevice');
	//Customized for seedcode
	var resourceGrid;
	var showRowLabels;
	var rowLabelWidth;
	var resources;
	var statuses;
	var breakoutItems;
	var cloumnHighlights;
	var breakoutField;
	var breakout;
	var weekHeaderFormat;

	var noFilterLabel = opt('noFilterLabel');
	var manageClone = t.manageClone;

	//Customized for seedcode - Add class to specify scroll area of calendar
	element.parent().addClass('calendar-scroll touch-scroll');

	/* Rendering
	------------------------------------------------------------*/
	element.addClass('fc-grid');
	//disableTextSelection(element.addClass('fc-grid'));

	function renderBasic(_rowCnt, _colCnt, _showNumbers, _clustering) {
		//Show horizontal row labels for resources - Customized for seedcode
		breakoutField = opt('horizonBreakoutField');
		breakout = opt('breakout', null, true);

		if (breakoutField === 'resource') {
			breakoutItems = t.getResources(false);
		} else if (breakoutField === 'status') {
			breakoutItems = opt('statusesAll')();
		} else if (breakoutField) {
			// Custom field
			breakoutItems =
				t.breakoutCustomFields && t.breakoutCustomFields.length
					? t.breakoutCustomFields
					: [{id: noFilterLabel, name: noFilterLabel, status: {}}];
		}

		showRowLabels = true;
		resourceGrid = true;

		rowCnt = breakoutField ? breakoutItems.length : 1; //_rowCnt; - changed to resource count as we are iterating on resources not dates //1;
		colCnt = _clustering ? _clustering.columnCount : _colCnt;
		clustering = _clustering;
		showNumbers = _showNumbers;
		updateOptions();

		cloumnHighlights = [];

		if (!body) {
			buildEventContainer();
		}

		buildTable();

		//Enable tooltips for this view //Customized for SeedCode
		calendar.generateBreakoutTooltips(
			'right',
			$(element),
			breakoutItems,
			false,
			breakoutField !== 'resource'
		);
	}

	function updateOptions() {
		tm = opt('theme') ? 'ui' : 'fc';
		colFormat = colCnt <= 7 ? opt('columnFormat') : 'ddd, D';
		showWeekNumbers =
			(opt('weekNumbers') && t.name !== 'basicWeek') ||
			(opt('weekNumbers') &&
				opt('titleFormat').toLowerCase() !== 'w' &&
				t.name === 'basicWeek');
	}

	function buildEventContainer() {
		//Add container for our event nubs - pills will go in the event container
		distanceContainer = $(
			"<div class='fc-distance-container' style='position:absolute;top:0;left:0;'/>"
		).appendTo(element);
		nubContainer = $(
			"<div class='fc-nub-container' style='position:absolute;top:0;left:0;'/>"
		).appendTo(element);
		daySegmentContainer = $(
			"<div class='fc-event-container' style='position:absolute;z-index:8;top:0;left:0;'/>"
		).appendTo(element);
	}

	function buildTable() {
		var html = buildTableHTML();

		if (table) {
			table.remove();
		}
		table = $(html).appendTo(element);

		head = table.find('thead.fc-fixed-head');
		breakoutHead = $('.fc-breakout-header');
		headCells = head.find('.fc-day-header');
		breakoutHeadCells = breakoutHead.find('.fc-day-header');
		body = table.find('tbody');
		bodyRows = body.find('tr.body-row');
		bodyCells = body.find('.fc-day');
		breakoutTitleRows = body.find('.horizon-breakout-title');
		breakoutTitleRowHeight = breakoutField
			? breakoutTitleRows.outerHeight()
			: 0;
		dayNumbers = $('.fc-day').find('.fc-day-number'); //Customized for seedcode
		bodyFirstCells = bodyRows.find('td:first-child');

		firstRowCellInners = bodyRows.eq(0).find('.fc-day > div');
		firstRowCellContentInners = bodyRows
			.eq(0)
			.find('.fc-day-content > div');

		markFirstLast(head.add(head.find('tr'))); // marks first+last tr/th's
		markFirstLast(bodyRows); // marks first+last td's
		bodyRows.eq(0).addClass('fc-first');
		bodyRows.filter(':last').addClass('fc-last');

		bodyCells.each(function (i, _cell) {
			var date = cellToDate(Math.floor(i / colCnt), i % colCnt);
			trigger('dayRender', t, date, $(_cell));
		});
		dayBind(bodyCells);

		//Bind day numbers - Customized for seedcode

		dayNumbers.each(function (i, dayNumber) {
			var date = cellToDate(Math.floor(i / colCnt), i % colCnt);
			$(dayNumber).data({date: date});
		});
		dayNumberBind(dayNumbers);

		var content = breakoutHead;
		$('.fc-header').before(content);
		// content.css('display', 'block');
		$('.fc-content').css(
			'top',
			breakoutHead.outerHeight() - head.outerHeight() + 'px'
		);

		//Click breakout header
		breakoutTitleRows.on('click', function (e) {
			var matchField;
			var matchValue;
			var selectedItem;
			var matchItem;
			var element = $(e.currentTarget);
			var id = element.data('breakoutId');
			var name = element.data('breakoutName');
			var callback = t.opt('onHorizonCollapse');
			if (!e.target.classList.contains('horizon-breakout-icon')) {
				return;
			}
			//Currently using breakout name only as we don't necessarily have unique id's
			// if (id) {
			// 	matchField = 'id';
			// 	matchValue = id;
			// }
			// else {
			matchField = 'nameSafe';
			matchValue =
				name !== undefined && name !== null ? name.toString() : ''; // Convert using toString in case the value is a number
			// }
			// Get object
			for (var i = 0; i < breakoutItems.length; i++) {
				if (breakoutItems[i][matchField] === matchValue) {
					matchItem = breakoutItems[i];
					break;
				}
			}

			// Set data
			matchItem.status.collapsed = !matchItem.status.collapsed;

			// Get scroll position
			var scrollPosition = $('.calendar-scroll').scrollTop();

			//Run optional callback
			if (callback) {
				callback(breakoutField, breakoutItems, matchItem);
			}

			// Re-render view. ToDo: save scroll position and restore
			calendar.render();

			// Set scroll position
			$('.calendar-scroll').scrollTop(scrollPosition);
		});
	}

	/* HTML Building
	-----------------------------------------------------------*/

	function buildTableHTML() {
		//Customized for seedcode to split the header and content. This way we can make the content scrollable independent from the head
		var additionalHead = '';
		if (breakoutHeader) {
			additionalHead =
				"<table class='fc-border-separate fc-breakout-header dbk_columnHeader' style='width: 100%;' cellspacing='0'>" +
				buildBreakoutHeadHTML(true, false) +
				'</table>';
		}

		var html =
			additionalHead +
			"<table class='fc-border-separate' style='width:100%' cellspacing='0'>" +
			buildHeadHTML(false, breakoutHeader) +
			buildBodyHTML() +
			'</table>';

		return html;
	}

	function buildHeadHTML(isBreakout, hideLabels) {
		var headerClass = tm + '-widget-header';
		var html = '';
		var col;
		var date;
		var today = calendar.getNow().stripTime();
		var designationClass = isBreakout ? 'breakout' : 'fixed-head'; //Customized for seedcode
		var hideStyle = hideLabels ? 'display: none;' : ''; //Customized for seedcode
		// var borderStyle = hideLabels ? 'border:none;' : ''; //Customized for seedcode
		var borderStyle = hideLabels ? '' : ''; //Customized for seedcode
		var todayClass;

		html +=
			"<thead class='fc-" + designationClass + " dbk_columnHeader'><tr>"; //Customized for seedcode

		if (showWeekNumbers) {
			// html +=
			// 	"<th class='fc-week-number " + headerClass + "' style='" + borderStyle + "'>" +
			// 	"<span style='" + hideStyle + "'>" + htmlEscape(opt('weekNumberTitle')) + "</span>" +
			// 	"</th>";
		}
		//customized for seedcode add vertical resource header
		// html +=
		// 	"<th class='fc-row-label fc-row-label-header " + headerClass + "' style='" + borderStyle + "'>" +
		// 	"<span style='" + hideStyle + "'>" + htmlEscape(opt('resourceText')) + "</span>" +
		// 	"</th>";
		for (col = 0; col < colCnt; col++) {
			if (clustering) {
				date = cellToDate(0, 0).add(
					col * clustering.typeOffset,
					clustering.type
				);
			} else {
				date = cellToDate(0, col);
			}
			todayClass = '';
			if (date.isSame(today, 'day')) {
				todayClass = 'fc-today';
			}
			html +=
				"<th class='fc-day-header " +
				todayClass +
				' fc-' +
				dayIDs[date.day()] +
				' ' +
				'dbk_day_' +
				dayIDs[date.day()] +
				' ' +
				headerClass +
				"' style='" +
				borderStyle +
				"'>" +
				"<span style='" +
				hideStyle +
				"'>" +
				htmlEscape(formatDate(date, colFormat)) +
				'</span>' +
				'</th>';
		}

		html += '</tr></thead>';

		return html;
	}

	function buildBreakoutHeadHTML(isBreakout, hideLabels) {
		if (clustering) {
			if (clustering.type === 'week') {
				return buildWeekHeadHTML(isBreakout, hideLabels);
			} else if (clustering.type === 'month') {
				return buildMonthHeadHTML(isBreakout, hideLabels);
			} else if (clustering.type === 'year') {
				return buildYearHeadHTML(isBreakout, hideLabels);
			}
		} else {
			return buildDayHeadHTML(isBreakout, hideLabels);
		}
	}

	function buildDayHeadHTML(isBreakout, hideLabels) {
		var headerClass = tm + '-widget-header';
		var html = '';
		var col;
		var date;
		var today = calendar.getNow().stripTime();
		var weekends = opt('weekends');
		var colCntOffset = !weekends ? (colCnt / 7) * 2 : 0;
		var firstDay = Number(opt('firstDay'));
		var weekText = opt('weekText');
		var weekNumberTitle = opt('weekNumberTitle');
		var weekOfText = opt('weekOfText');
		var dynamicHead = true;
		var colDisplayFormat = colFormat;
		var weekHeaderString;
		var weekHeaderDisplay;
		var designationClass = isBreakout ? 'breakout' : 'fixed-head'; //Customized for seedcode
		var hideStyle = hideLabels ? 'display: none;' : ''; //Customized for seedcode
		var borderStyle = hideLabels ? 'border: none;' : ''; //Customized for seedcode
		var dynamicDayHeadStyle = !dynamicHead ? 'display: none' : '';
		var dynamicWeekHeadStyle;
		var additionalColClass = '';
		var weekColWidth =
			calendar.getContentWidth() / ((colCnt + colCntOffset) / 7);
		var todayClass;

		if (dynamicHead) {
			designationClass += ' dynamic-head has-slider';
			colDisplayFormat = 'M/D';
			//Correct first day if it is hidden
			if ((firstDay === 6 || firstDay === 0) && !weekends) {
				firstDay = 1;
			}

			//Assign our default string - This can be changed further down when necessary
			weekHeaderString = showWeekNumbers ? weekText : weekOfText;

			//Determine week header format
			if (colCnt + colCntOffset < 63 && weekColWidth > 275) {
				weekHeaderFormat = 'MMMM Do YYYY';
			} else if (colCnt + colCntOffset < 32 && weekColWidth > 200) {
				weekHeaderFormat = 'MMMM Do';
			} else if (colCnt + colCntOffset < 49 && weekColWidth > 175) {
				weekHeaderFormat = 'MMM Do';
			} else if (colCnt + colCntOffset < 63 && weekColWidth > 150) {
				weekHeaderFormat = 'M/D';
			} else if (colCnt + colCntOffset < 63) {
				weekHeaderFormat = 'MMMM';
				weekHeaderString = '';
			} else if (colCnt + colCntOffset > 62) {
				weekHeaderFormat = 'MMMM';
				weekHeaderString = '';
			}
		}

		html +=
			"<thead class='fc-" + designationClass + " dbk_columnHeader'><tr>"; //Customized for seedcode

		if (showWeekNumbers) {
			// html +=
			// 	"<th class='fc-week-number " + headerClass + "' style='" + borderStyle + "'>" +
			// 	"<span style='" + hideStyle + "'>" + htmlEscape(opt('weekNumberTitle')) + "</span>" +
			// 	"</th>";
		}
		//customized for seedcode add vertical resource header
		// html +=
		// 	"<th class='fc-row-label fc-row-label-header " + headerClass + "' style='" + borderStyle + "'>" +
		// 	"<span style='" + hideStyle + "'>" + htmlEscape(opt('resourceText')) + "</span>" +
		// 	"</th>";
		for (col = 0; col < colCnt; col++) {
			if (clustering) {
				date = cellToDate(0, 0).add(
					col * clustering.typeOffset,
					clustering.type
				);
			} else {
				date = cellToDate(0, col);
			}

			todayClass = '';
			if (date.isSame(today, 'day')) {
				todayClass = 'fc-today';
			}
			//We are showing dates in months
			if (weekHeaderFormat === 'MMMM') {
				dynamicWeekHeadStyle =
					!dynamicHead || 1 != date.format('D')
						? 'display: none'
						: '';
				weekHeaderDisplay = htmlEscape(
					formatLocalizedDate(date, weekHeaderFormat)
				);
			}
			//We are showing dates in weeks
			else {
				dynamicWeekHeadStyle =
					!dynamicHead || firstDay !== Number(date.format('d'))
						? 'display: none'
						: '';
				weekHeaderDisplay = showWeekNumbers
					? weekHeaderString +
					  ' ' +
					  htmlEscape(calculateWeekNumber(date, 7))
					: weekHeaderString +
					  ' ' +
					  htmlEscape(formatLocalizedDate(date, weekHeaderFormat));
			}

			additionalColClass =
				date.day() === firstDay ? ' fc-start-week' : '';

			html +=
				"<th data-date='" +
				date.format('YYYY-MM-DD') +
				"' class='fc-day-header " +
				todayClass +
				' fc-' +
				dayIDs[date.day()] +
				' ' +
				'dbk_day_' +
				dayIDs[date.day()] +
				' ' +
				headerClass +
				additionalColClass +
				"' style='" +
				borderStyle +
				"'>" +
				"<span class='day-header-week' style='" +
				dynamicWeekHeadStyle +
				"'>" +
				weekHeaderDisplay +
				'</span>' +
				"<span class='day-header-date' style='" +
				hideStyle +
				"'>" +
				(weekHeaderFormat === 'MMMM' && showWeekNumbers
					? weekNumberTitle + htmlEscape(calculateWeekNumber(date, 7))
					: htmlEscape(formatLocalizedDate(date, colDisplayFormat))) +
				'</span>' +
				"<span class='day-header-day' style='" +
				dynamicDayHeadStyle +
				"'>" +
				htmlEscape(formatDate(date, 'ddd')).charAt(0) +
				'</span>' +
				'</th>';
		}

		html += '</tr></thead>';

		return html;
	}

	function buildWeekHeadHTML(isBreakout, hideLabels) {
		var headerClass = tm + '-widget-header';
		var html = '';
		var col;
		var date;
		var today = calendar.getNow().stripTime();
		var weekText = opt('weekText');
		var weekOfText = opt('weekOfText');
		var weekNumberTitle = opt('weekNumberTitle');
		var quarterText = opt('quarterText');
		var fiscalYearStarts = opt('fiscalYearStarts');
		var colWidth = calendar.getContentWidth() / colCnt;

		var designationClass = isBreakout ? 'breakout' : 'fixed-head'; //Customized for seedcode
		var hideStyle = hideLabels ? 'display: none;' : ''; //Customized for seedcode
		var borderStyle = hideLabels ? 'border: none;' : ''; //Customized for seedcode
		var additionalColClass = '';
		var todayClass = '';

		var topHeaderClass = '';
		var topHeaderStyle = '';
		var topHeaderDisplay = '';
		var middleHeaderStyle = '';
		var middleHeaderDisplay = '';
		var bottomHeaderClass = '';
		var bottomHeaderStyle = '';
		var bottomHeaderDisplay = '';
		var month;
		var quarter;
		var year;
		var yearChange;
		var lookBackDate;

		var monthWeekCounter;

		designationClass += ' dynamic-head has-slider';

		html +=
			"<thead class='fc-" + designationClass + " dbk_columnHeader'><tr>"; //Customized for seedcode

		for (col = 0; col < colCnt; col++) {
			date = t.start
				.clone()
				.add(col * clustering.typeOffset, clustering.type);

			dateDay = date.date();

			if (month === undefined) {
				if (date.clone().subtract(7, 'days').month() !== date.month()) {
					middleHeaderStyle = '';
					monthWeekCounter = 0;
				} else {
					// Adjust for days before the month if not starting on a month
					if (
						date.clone().subtract(2, 'weeks').month() !==
						date.month()
					) {
						monthWeekCounter = 1;
					} else if (
						date.clone().subtract(3, 'weeks').month() !==
						date.month()
					) {
						monthWeekCounter = 2;
					}

					middleHeaderStyle = 'display: none;';
				}
			} else {
				if (date.month() !== month) {
					middleHeaderStyle = '';
					monthWeekCounter = 0;
				} else {
					middleHeaderStyle = 'display: none;';
				}
			}

			monthWeekCounter++;

			if (monthWeekCounter === 1 || monthWeekCounter === 3) {
				bottomHeaderClass = 'primary-header';
			} else {
				bottomHeaderClass = 'optional-header';
			}

			middleHeaderDisplay =
				colCnt >= 50 || (colWidth < 16 && colCnt >= 24)
					? formatLocalizedDate(date, 'MMM')
					: formatLocalizedDate(date, 'MMMM');

			// middleHeaderDisplay += date.quarter() !== quarter ? ' <div class="day-header-date-sub">' + htmlEscape(quarterText) + date.quarter() + '</div>' : '';

			yearChange = year !== date.year();
			month = date.month();
			year = date.year();

			if (!middleHeaderStyle) {
				cloumnHighlights.push(true);
			} else {
				cloumnHighlights.push(false);
			}

			topHeaderDisplay = '';
			topHeaderStyle = 'display: none;';

			if (
				date.fquarter(fiscalYearStarts).quarter !== quarter ||
				yearChange
			) {
				lookBackDate = date.clone().subtract(1, 'week');
				quarter = date.fquarter(fiscalYearStarts).quarter;
				if (
					lookBackDate.fquarter(fiscalYearStarts).quarter !==
						quarter ||
					lookBackDate.year() !== year
				) {
					if (month === 0 && !middleHeaderStyle) {
						topHeaderStyle = '';
						topHeaderClass = '';
						topHeaderDisplay = year;
					} else {
						topHeaderClass = 'day-header-date-sub-tick';
						topHeaderStyle = '';
					}
					topHeaderDisplay +=
						date
							.clone()
							.subtract(1, 'week')
							.fquarter(fiscalYearStarts).quarter !== quarter
							? ' <div class="day-header-date-sub">' +
							  htmlEscape(quarterText) +
							  date.fquarter(fiscalYearStarts).quarter +
							  '</div>'
							: '';
				}
			}

			if (showWeekNumbers) {
				//Calculate whether or not to show week number title
				if (colWidth < 8 + 8 * (weekNumberTitle.length + 1)) {
					bottomHeaderDisplay = htmlEscape(
						calculateWeekNumber(date, 7)
					);
				} else {
					bottomHeaderDisplay =
						weekNumberTitle +
						htmlEscape(calculateWeekNumber(date, 7));
				}
			} else {
				bottomHeaderDisplay = htmlEscape(formatDate(date, 'DD'));
			}

			html +=
				"<th data-date='" +
				date.format('YYYY-MM-DD') +
				"' class='fc-day-header long-timescale-header " +
				todayClass +
				' fc-' +
				dayIDs[date.day()] +
				' ' +
				'dbk_day_' +
				dayIDs[date.day()] +
				' ' +
				headerClass +
				additionalColClass +
				"' style='" +
				borderStyle +
				"'>" +
				"<span class='day-header-week " +
				topHeaderClass +
				"' style='" +
				topHeaderStyle +
				"'>" +
				topHeaderDisplay +
				'</span>' +
				"<span class='day-header-date' style='" +
				hideStyle +
				' ' +
				middleHeaderStyle +
				"'>" +
				middleHeaderDisplay +
				'</span>' +
				"<span class='day-header-day " +
				bottomHeaderClass +
				"' style='" +
				bottomHeaderStyle +
				"'>" +
				bottomHeaderDisplay +
				'</span>' +
				'</th>';
		}

		html += '</tr></thead>';

		return html;
	}

	function buildMonthHeadHTML(isBreakout, hideLabels) {
		var headerClass = tm + '-widget-header';
		var html = '';
		var col;
		var date;
		var today = calendar.getNow().stripTime();

		var quarterText = opt('quarterText');
		var fiscalYearStarts = opt('fiscalYearStarts');
		var colWidth = calendar.getContentWidth() / colCnt;

		var designationClass = isBreakout ? 'breakout' : 'fixed-head'; //Customized for seedcode
		var hideStyle = hideLabels ? 'display: none;' : ''; //Customized for seedcode
		var borderStyle = hideLabels ? 'border: none;' : ''; //Customized for seedcode
		var additionalColClass = '';
		var todayClass = '';

		var topHeaderStyle = '';
		var topHeaderDisplay = '';
		var middleHeaderStyle = '';
		var middleHeaderDisplay = '';
		var bottomHeaderClass = '';
		var bottomHeaderStyle = '';
		var bottomHeaderDisplay = '';
		var dateDay;
		var month;
		var quarter;
		var year;
		var yearChange;

		var quarterMonthCounter;

		designationClass += ' dynamic-head has-slider';

		html +=
			"<thead class='fc-" + designationClass + " dbk_columnHeader'><tr>"; //Customized for seedcode
		bottomHeaderDisplay = colCnt > 10 || colWidth < 54 ? 'MMM' : 'MMMM';

		for (col = 0; col < colCnt; col++) {
			date = t.start
				.clone()
				.add(col * clustering.typeOffset, clustering.type);

			dateDay = date.date();

			if (quarter === undefined) {
				quarter = date.fquarter(fiscalYearStarts).quarter;
				if (
					date.clone().subtract(1, 'month').fquarter(fiscalYearStarts)
						.quarter !== quarter
				) {
					middleHeaderStyle = '';
					quarterMonthCounter = 0;
				} else {
					middleHeaderStyle = 'display: none;';
				}
			} else {
				if (date.fquarter(fiscalYearStarts).quarter !== quarter) {
					middleHeaderStyle = '';
					quarterMonthCounter = 0;
				} else {
					middleHeaderStyle = 'display: none;';
				}
			}

			quarterMonthCounter++;

			if (quarterMonthCounter === 1) {
				bottomHeaderClass = 'primary-header';
			} else {
				bottomHeaderClass = 'optional-header';
			}

			yearChange = year !== date.year();

			month = date.month();
			quarter = date.fquarter(fiscalYearStarts).quarter;
			year = date.year();

			if (quarter === 1) {
				middleHeaderDisplay = quarterText + quarter;
			} else {
				middleHeaderDisplay = quarter;
			}

			if (
				(month === 0 && !middleHeaderStyle) ||
				(month === 0 && yearChange)
			) {
				topHeaderStyle = '';
				topHeaderDisplay = year;
			} else {
				topHeaderStyle = 'display: none;';
			}

			if (!middleHeaderStyle) {
				cloumnHighlights.push(true);
			} else {
				cloumnHighlights.push(false);
			}

			html +=
				"<th data-date='" +
				date.format('YYYY-MM-DD') +
				"' class='fc-day-header long-timescale-header " +
				todayClass +
				' fc-' +
				dayIDs[date.day()] +
				' ' +
				'dbk_day_' +
				dayIDs[date.day()] +
				' ' +
				headerClass +
				additionalColClass +
				"' style='" +
				borderStyle +
				"'>" +
				"<span class='day-header-week' style='" +
				topHeaderStyle +
				"'>" +
				topHeaderDisplay +
				'</span>' +
				"<span class='day-header-date' style='" +
				hideStyle +
				' ' +
				middleHeaderStyle +
				"'>" +
				htmlEscape(middleHeaderDisplay) +
				'</span>' +
				"<span class='day-header-day " +
				bottomHeaderClass +
				"' style='" +
				bottomHeaderStyle +
				"'>" +
				htmlEscape(formatDate(date, bottomHeaderDisplay)) +
				'</span>' +
				'</th>';
		}

		html += '</tr></thead>';

		return html;
	}

	function buildYearHeadHTML(isBreakout, hideLabels) {
		var headerClass = tm + '-widget-header';
		var html = '';
		var col;
		var date;
		var today = calendar.getNow().stripTime();

		var designationClass = isBreakout ? 'breakout' : 'fixed-head'; //Customized for seedcode
		var hideStyle = hideLabels ? 'display: none;' : ''; //Customized for seedcode
		var borderStyle = hideLabels ? 'border: none;' : ''; //Customized for seedcode
		var additionalColClass = '';
		var todayClass = '';

		var topHeaderStyle = '';
		var topHeaderDisplay = '';
		var middleHeaderStyle = '';
		var bottomHeaderClass = '';
		var bottomHeaderStyle = '';
		var bottomHeaderDisplay = '';

		var year;

		designationClass += ' dynamic-head has-slider';

		html +=
			"<thead class='fc-" + designationClass + " dbk_columnHeader'><tr>"; //Customized for seedcode

		for (col = 0; col < colCnt; col++) {
			date = t.start
				.clone()
				.add(col * clustering.typeOffset, clustering.type);

			dateDay = date.date();

			year = date.year();

			if (year % (10 * clustering.typeOffset) === 0) {
				middleHeaderStyle = '';
			} else {
				middleHeaderStyle = 'display: none;';
			}

			if (year % (100 * clustering.typeOffset) === 0) {
				topHeaderStyle = '';
				topHeaderDisplay = year;
			} else {
				topHeaderStyle = 'display: none;';
			}

			if (clustering.typeOffset === 100) {
				bottomHeaderDisplay = 'YYYY';

				if (
					(colCnt <= 50 &&
						(year / clustering.typeOffset) % 2 === 0) ||
					(colCnt > 50 && (year / clustering.typeOffset) % 5 === 0)
				) {
					bottomHeaderClass = 'primary-header';
				} else {
					bottomHeaderClass = 'optional-header';
				}
			} else {
				bottomHeaderDisplay = "'YY";
				if ((year / clustering.typeOffset) % 2 === 0) {
					bottomHeaderClass = 'primary-header';
				} else {
					bottomHeaderClass = 'optional-header';
				}
			}

			if (!middleHeaderStyle) {
				cloumnHighlights.push(true);
			} else {
				cloumnHighlights.push(false);
			}

			html +=
				"<th data-date='" +
				date.format('YYYY-MM-DD') +
				"' class='fc-day-header long-timescale-header " +
				todayClass +
				' fc-' +
				dayIDs[date.day()] +
				' ' +
				'dbk_day_' +
				dayIDs[date.day()] +
				' ' +
				headerClass +
				additionalColClass +
				"' style='" +
				borderStyle +
				"'>" +
				"<span class='day-header-week' style='" +
				topHeaderStyle +
				"'>" +
				topHeaderDisplay +
				'</span>' +
				"<span class='day-header-date' style='" +
				hideStyle +
				' ' +
				middleHeaderStyle +
				"'>" +
				year +
				'</span>' +
				"<span class='day-header-day " +
				bottomHeaderClass +
				"' style='" +
				bottomHeaderStyle +
				"'>" +
				htmlEscape(formatDate(date, bottomHeaderDisplay)) +
				'</span>' +
				'</th>';
		}

		html += '</tr></thead>';

		return html;
	}

	function buildBodyHTML() {
		var contentClass = tm + '-widget-content dbk_cellContent';
		var html = '';
		var row;
		var col;
		var date;
		var weekNumberContent;
		var weekNumberBorderStyle;
		var breakoutItem;
		var breakoutLabel;
		var breakoutFieldClass;
		var breakoutTitleClass;
		var caretClass;
		var breakoutHeaderClass;

		html += "<tbody class='dbk_calendarContent'>";
		for (row = 0; row < rowCnt; row++) {
			breakoutFieldClass = '';
			breakoutTitleClass = row === rowCnt - 1 ? ' fc-last' : '';
			if (breakoutField) {
				breakoutItem = breakoutItems[row];
				breakoutFieldClass = ' breakout-by';

				if (breakoutItem.status && breakoutItem.status.collapsed) {
					breakoutFieldClass += ' breakout-collapsed';
					caretClass = 'fa-caret-right';
					breakoutTitleClass += ' breakout-title-collapsed';
				} else {
					caretClass = 'fa-caret-down';
				}

				if (breakoutItem.display) {
					breakoutLabel = breakoutItem.display;
				} else {
					breakoutLabel = breakoutItem.mutate
						? mutate(breakoutItem.name)
						: breakoutItem.name;
				}

				breakoutLabel =
					"<div class='name'>" +
					"<span data-toggle='tooltip' data-position='" +
					row +
					"'>" +
					breakoutLabel +
					'</span>' +
					'</div>';

				if (breakoutItem.descriptionDisplay) {
					breakoutLabel = breakoutLabel +=
						"<div class='description'>" +
						breakoutItem.descriptionDisplay +
						'</div>';
				}
				breakoutHeaderClass = breakoutItem.class
					? ' ' + breakoutItem.class
					: '';

				html +=
					"<tr data-breakout-id='" +
					breakoutItem.id +
					"' data-breakout-name='" +
					breakoutItem.nameSafe +
					"' class='horizon-breakout-title" +
					breakoutTitleClass +
					breakoutHeaderClass +
					"'><td colspan='" +
					colCnt +
					"'>" +
					"<div class='breakout-title-wrapper'>" +
					"<i class='horizon-breakout-icon fa " +
					caretClass +
					"'></i>" +
					"<div class='breakout-title-content'>" +
					(breakout && breakoutItem.name === noFilterLabel
						? breakout.label + ': ' + breakoutLabel
						: breakoutLabel) +
					'</span>' +
					'</div>' +
					'</div>' +
					'</td></tr>';
			}

			html += "<tr class='fc-week body-row" + breakoutFieldClass + "'>";

			if (showWeekNumbers) {
				// date = cellToDate(0, 0);
				// weekNumberContent = row === 0 ? htmlEscape(calculateWeekNumber(date)) : '';
				// weekNumberBorderStyle = row !== 0 ? "border-top-color: transparent;" : '';
				// html +=
				// 	"<td class='fc-week-number " + contentClass + "' style='" + weekNumberBorderStyle + "'>" +
				// 	"<div>" +
				// 	weekNumberContent +
				// 	"</div>" +
				// 	"</td>";
			}
			//Customized for seedcode to add resources
			// html +=
			// 	"<td class='fc-row-label fc-row-label-content " + contentClass + "'>" +
			// 	"<div>" +
			// 	resource +
			// 	"</div>" +
			// 	"</td>";
			for (col = 0; col < colCnt; col++) {
				if (clustering) {
					date = date = t.start
						.clone()
						.add(col * clustering.typeOffset, clustering.type);
				} else {
					date = cellToDate(0, col);
				}

				// html += buildCellHTML(date);
				html += buildCellHTML(date, breakoutItem, col, row);
			}

			html += '</tr>';
		}

		html += '</tbody>';

		return html;
	}

	function buildCellHTML(date, breakoutItem, col, breakoutPosition) {
		// date assumed to have stripped time

		if (!breakoutItem) {
			breakoutItem = {};
		}
		var month = t.intervalStart.month();
		var today = calendar.getNow().stripTime();
		var cell = dateToCell(date); //Customized for seedcode so the day number can reference a specific cell. Used to insert more events label.
		var weekends = opt('weekends');
		var firstDay = Number(opt('firstDay'));
		var html = '';
		var contentClass = tm + '-widget-content dbk_cellContent';
		var classNames = ['fc-day', contentClass];

		if (!clustering) {
			classNames.push('fc-' + dayIDs[date.day()]);
			classNames.push('dbk_day_' + dayIDs[date.day()]);

			//Correct first day if it is hidden
			if ((firstDay === 6 || firstDay === 0) && !weekends) {
				firstDay = 1;
			}

			if (date.day() === firstDay) {
				classNames.push('fc-start-week');
			}

			if (date.month() != month) {
				classNames.push('fc-other-month');
			}
			if (date.isSame(today, 'day')) {
				classNames.push(
					'fc-today',
					tm + '-state-highlight',
					'dbk_day_today'
				);
			} else if (date < today) {
				classNames.push('fc-past');
			} else {
				classNames.push('fc-future');
			}
		} else {
			if (cloumnHighlights[col]) {
				classNames.push('fc-start-week');
			}
		}

		html +=
			'<td' +
			" class='" +
			classNames.join(' ') +
			"'" +
			" data-date='" +
			date.format() +
			"'" +
			" data-resource='" +
			breakoutItem.nameSafe +
			"'" +
			" data-breakout-position='" +
			breakoutPosition +
			"'" +
			'>' +
			'<div>';

		//Customized for seedcode for show more and added class to target clicks for show day on click
		if (showNumbers) {
			html +=
				"<div class='dbk_dayNumberHeader'><div id='fc-cell-" +
				cell.row +
				'-' +
				cell.col +
				"' class='fc-day-number'>" +
				"<span class='more-events'></span>" +
				date.date() +
				'</div></div>'; //Customized for seedcode to contain cell reference
		}

		html +=
			"<div class='fc-day-content'>" +
			"<div style='position:relative'>&nbsp;</div>" +
			'</div>' +
			'</div>' +
			'</td>';
		return html;
	}

	/* Dimensions
	-----------------------------------------------------------*/

	function setHeight(height) {
		viewHeight = height;
		var headHeight = breakoutHeader
			? breakoutHead.outerHeight()
			: head.height(); //Customized for seedcode
		var bodyHeight = Math.max(viewHeight - headHeight, 0); //Customized for seedcode
		var rowHeight;
		var rowHeightLast;
		var cell;
		var rows = rowCnt;

		t.horizonHeight = {
			bodyHeight: bodyHeight,
			breakoutTitleRowHeight: breakoutTitleRowHeight,
		};

		if (opt('weekMode') == 'variable') {
			rowHeight = rowHeightLast = Math.floor(
				bodyHeight / (rows == 1 ? 2 : 6) - breakoutTitleRowHeight
			);
		} else {
			rowHeight = 46;
			t.horizonHeight.rowHeight = rowHeight;
			rowHeightLast =
				bodyHeight -
				rowHeight * (rows - 1) -
				breakoutTitleRowHeight * rows;
			// rowHeight = Math.floor((bodyHeight / rows) - breakoutTitleRowHeight);
			// rowHeightLast = bodyHeight - rowHeight * (rows - 1) - breakoutTitleRowHeight * rows;
		}

		bodyFirstCells.each(function (i, _cell) {
			var cssParentElement;
			var cssElement;
			var heightOffset = 5;
			var adjustedRowHeight;
			var minHeight;
			t.horizonHeight.heightOffset = heightOffset;
			if (i < rows) {
				cell = $(_cell);
				cssParentElement = cell; //.find('.fc-day-content');
				cssElement = cssParentElement.find('.fc-day-content > div');
				// heightOffset = cssParentElement.outerHeight() - cssElement.outerHeight();
				// if (i === 0) {
				// 	t.horizonHeight.heightOffset = heightOffset;
				// }
				if (
					breakoutItems &&
					breakoutItems[i].status &&
					breakoutItems[i].status.collapsed
				) {
					adjustedRowHeight = 0;
					minHeight = 0;
					cssElement.parent().css('padding', 0);
					//Adjust last row height based on collapsed row - Collapsed rows don't have the same height offset. Just two 1px borders.
					rowHeightLast += rowHeight - 2;
				} else {
					adjustedRowHeight =
						(i === rows - 1 ? rowHeightLast : rowHeight) -
						heightOffset;
					minHeight = rowHeight - heightOffset;
				}

				//Set min height
				cssElement.css('min-height', minHeight);
				//Set height based on how many rows are being displayed. Subtracting height offset caused by any padding or border lines
				cssElement.css(
					'height',
					adjustedRowHeight
					// (i == rows - 1 ? rowHeightLast : rowHeight) - vsides(cell) - heightOffset
				);
			}
		});
	}

	function setWidth(width) {
		viewWidth = width;
		colPositions.clear();
		colContentPositions.clear();
		var breakPoint = 50;
		var scrollbarWidth = isMobileDevice ? 0 : 18;
		var firstDay = Number(opt('firstDay'));
		var colModulus;

		var headerColWidthModifier = 1;
		var newHeaderDayClass;

		weekNumberWidth = 0;
		rowLabelWidth = 0;
		if (showWeekNumbers) {
			// weekNumberWidth = head.find('th.fc-week-number').outerWidth();
			// weekNumberWidth = breakoutHead.find('th.fc-week-number').outerWidth();
		}
		if (showRowLabels) {
			rowLabelWidth = head.find('th.fc-row-label').outerWidth() || 0;
		}

		//Chrome currently doesn't support sub pixel rendering on tables so we are not rounding only on browsers that aren't chrome
		if (!isChrome) {
			//Don't round column width
			colWidth =
				(viewWidth - scrollbarWidth - weekNumberWidth - rowLabelWidth) /
				colCnt;
		} else {
			//Round column width
			colWidth = Math.floor(
				(viewWidth -
					scrollbarWidth / 2 -
					weekNumberWidth -
					rowLabelWidth) /
					colCnt
			); //scrollbar width / 2 is here just so we never fully cover the last column with the scroll bar
		}

		//Set header column widths
		setOuterWidth(headCells.slice(0, -1), colWidth);
		setOuterWidth(breakoutHeadCells.slice(0, -1), colWidth);

		//Customized for SeedCode. Apply column width to label in last column so a long label doesn't push content. Remove 2 at the end for a bit of breathing room.
		var lastColWidth =
			viewWidth -
			scrollbarWidth -
			weekNumberWidth -
			rowLabelWidth -
			colWidth * (colCnt - 1) -
			2;
		setOuterWidth(headCells.slice(-1).find('span'), lastColWidth);
		setOuterWidth(breakoutHeadCells.slice(-1).find('span'), lastColWidth);

		colModulus =
			weekHeaderFormat && weekHeaderFormat === 'MMMM' && showWeekNumbers
				? opt('weekends')
					? 7
					: 5
				: Math.ceil(breakPoint / colWidth);
		var adjustedColWidth = colWidth * colModulus;
		var adjustedLastColWidth =
			viewWidth -
			weekNumberWidth -
			rowLabelWidth -
			adjustedColWidth * (colCnt - 1) -
			2;

		var lastViewedCol;
		var visibleColumnHeaders = [];

		//Format breakout head ticks depending on if it is the first day of week or matches the modulus
		breakoutHeadCells.each(function (i, element) {
			$(element).css({left: colWidth * i + 'px'});
			if (
				(showWeekNumbers &&
					weekHeaderFormat === 'MMMM' &&
					moment($(element).attr('data-date')).day() !== firstDay) ||
				((!showWeekNumbers ||
					!weekHeaderFormat ||
					weekHeaderFormat !== 'MMMM') &&
					i % colModulus &&
					!clustering)
			) {
				$(element).addClass('tick');
				$(element).removeClass('tick-label');
			} else {
				$(element).addClass('tick-label');
				$(element).removeClass('tick');
				visibleColumnHeaders.push(breakoutHeadCells[i]);

				lastViewedCol = i;
			}
		});

		//Set header classes based on column widths
		if (clustering && clustering.grouping === 'month') {
			headerColWidthModifier = 2.8;
		} else if (clustering && clustering.grouping === 'century') {
			headerColWidthModifier = 3.4;
		} else if (clustering) {
			headerColWidthModifier = 2.25;
		}

		if (colWidth > 16 * headerColWidthModifier) {
			newHeaderDayClass = 'col-lg';
		} else if (colWidth > 12 * headerColWidthModifier) {
			newHeaderDayClass = 'col-md';
		} else if (colWidth > 8 * headerColWidthModifier) {
			newHeaderDayClass = 'col-sm';
		} else if (colWidth > 6 * headerColWidthModifier) {
			newHeaderDayClass = 'col-xs';
		} else if (colWidth <= 6 * headerColWidthModifier) {
			newHeaderDayClass = 'col-xxs';
		}

		if (breakoutHead.hasClass(headerDayClass)) {
			breakoutHead.removeClass(headerDayClass);
		}
		breakoutHead.addClass(newHeaderDayClass);
		headerDayClass = newHeaderDayClass;

		bodyPosition = body[0].getBoundingClientRect();
	}

	/* Day clicking and binding
	-----------------------------------------------------------*/

	//Customized for seedcode to allow clicking on day numbers
	function dayNumberBind(dayNumbers) {
		dayNumbers.click(dayNumberClick);
	}

	function dayNumberClick(ev) {
		if (!opt('selectable')) {
			// if selectable, SelectionManager will worry about dayClick
			var date = calendar.moment($(this).data('date'));
			trigger('dayNumberClick', this, date, ev);
		}
	}

	function dayBind(days) {
		days.click(dayClick).mousedown(daySelectionMousedown);
		days.dblclick(dayDblClick);
		days.addTouch(true);
	}

	function longTouch(ev) {
		if (!opt('selectable')) {
			// if selectable, SelectionManager will worry about dayClick
			dayDblClick(ev.originalEvent.touches[0]);
		}
	}

	function dayClick(ev) {
		if (!opt('selectable')) {
			// if selectable, SelectionManager will worry about dayClick
			var date = calendar.moment($(this).data('date'));
			trigger('dayClick', this, date, ev);
		}
	}

	//Customized for seedcode for double click
	function dayDblClick(ev) {
		if (!opt('selectable')) {
			// if selectable, SelectionManager will worry about dayClick
			var breakoutPosition;
			var resource;
			var date = calendar.moment($(this).data('date'));
			if (breakoutField) {
				breakoutPosition = $(this).data('breakout-position');
				resource = breakoutItems[breakoutPosition]
					? breakoutItems[breakoutPosition]
					: null;
				ev.resource = resource;
			}
			trigger('dayDblClick', this, date, ev);
		}
	}

	/* Semi-transparent Overlay Helpers
	------------------------------------------------------*/
	// TODO: should be consolidated with AgendaView's methods

	function renderDayOverlay(
		overlayStart,
		overlayEnd,
		refreshCoordinateGrid,
		breakoutItem
	) {
		// overlayEnd is exclusive

		if (refreshCoordinateGrid) {
			coordinateGrid.build();
		}
		var segments = resourceGridRangeToSegments(
			overlayStart,
			overlayEnd,
			breakoutItems,
			breakoutItem,
			true,
			false,
			clustering
		);

		for (var i = 0; i < segments.length; i++) {
			var segment = segments[i];
			if (
				!breakoutField ||
				!breakoutItems[segment.row].status ||
				!breakoutItems[segment.row].status.collapsed
			) {
				// Only render overlay if the row isn't collapsed
				dayBind(
					renderCellOverlay(
						segment.row,
						segment.leftCol,
						segment.row,
						segment.rightCol
					)
				);
			}
		}
	}

	function renderCellOverlay(row0, col0, row1, col1) {
		// row1,col1 is inclusive
		var rect = coordinateGrid.rect(row0, col0, row1, col1, element);
		return renderOverlay(rect, element);
	}

	/* Selection
	-----------------------------------------------------------------------*/

	function defaultSelectionEnd(start) {
		return start.clone().stripTime().add(1, 'days');
	}

	function renderSelection(start, end) {
		// end is exclusive
		renderDayOverlay(start, end, true); // true = rebuild every time
	}

	function clearSelection() {
		clearOverlays();
	}

	function reportDayClick(date, ev) {
		var cell = dateToCell(date);
		var _element = bodyCells[cell.row * colCnt + cell.col];
		trigger('dayClick', _element, date, ev);
	}

	/* External Dragging
	-----------------------------------------------------------------------*/

	function dragStart(_dragElement, ev, ui) {
		hoverListener.start(function (cell) {
			const draggedEvent = ui?.helper?.data;

			clearOverlays();
			if (
				cell &&
				(!breakoutField ||
					!breakoutItems[cell.row].status ||
					!breakoutItems[cell.row].status.collapsed)
			) {
				const dateCell = {row: 0, col: cell.col};
				const newStart = clustering
					? t.start
							.clone()
							.add(
								cell.col * clustering.typeOffset,
								clustering.type
							)
					: cellToDate(dateCell);
				const dates = createExternalDragDayDates(
					newStart,
					draggedEvent
				);
				if (breakoutField) {
					const breakoutRowItem = breakoutItems[cell.row];
					renderDayOverlay(dates.start, dates.end, false, [
						breakoutRowItem.name,
					]);
				} else {
					renderDayOverlay(dates.start, dates.end);
				}
			}
		}, ev);
	}

	function dragStop(_dragElement, ev, ui) {
		const cell = hoverListener.stop();
		const draggedEvent = ui?.helper?.data;
		const isClone = manageClone(ev, ui, draggedEvent);

		clearOverlays();
		if (
			cell &&
			(!breakoutField ||
				!breakoutItems[cell.row].status ||
				!breakoutItems[cell.row].status.collapsed)
		) {
			const dateCell = {row: 0, col: cell.col};
			const newStart = clustering
				? t.start
						.clone()
						.add(cell.col * clustering.typeOffset, clustering.type)
				: cellToDate(dateCell);
			const dates = createExternalDragDayDates(newStart, draggedEvent);
			trigger(
				'drop',
				_dragElement,
				dates.start,
				dates.end,
				ev,
				ui,
				breakoutField
					? {value: breakoutItems[cell.row], field: breakoutField}
					: null,
				isClone
			);
		}
	}

	/* Utilities
	--------------------------------------------------------*/

	coordinateGrid = new CoordinateGrid(function (rows, cols) {
		var e, n, p;
		headCells.each(function (i, _e) {
			e = $(_e);
			n = e.offset().left;
			if (i) {
				p[1] = n;
			}
			p = [n];
			cols[i] = p;
		});
		p[1] = n + e.outerWidth();
		bodyRows.each(function (i, _e) {
			if (i < rowCnt) {
				e = $(_e);
				n = e.offset().top;
				if (i) {
					p[1] = n - breakoutTitleRowHeight;
				}
				p = [n];
				rows[i] = p;
			}
		});
		p[1] = n + e.outerHeight();
	});

	hoverListener = new HoverListener(coordinateGrid);

	colPositions = new HorizontalPositionCache(function (col) {
		return {bodyPosition: bodyPosition, cell: firstRowCellInners.eq(col)};
	});

	colContentPositions = new HorizontalPositionCache(function (col) {
		return {
			bodyPosition: bodyPosition,
			cell: firstRowCellContentInners.eq(col),
		};
	});

	function colLeft(col) {
		return colPositions.left(col);
	}

	function colRight(col) {
		return colPositions.right(col);
	}

	function colContentLeft(col) {
		return colContentPositions.left(col);
	}

	function colContentRight(col) {
		return colContentPositions.right(col);
	}

	function allDayRow(i) {
		return bodyRows.eq(i);
	}
}
